<template>
    <div>
        <div class="container-fluid form-inline header_div">
            <a href="/" class="logo_link" no-prefetch><img src="/vstatic/images/logo.png" class="logo"/></a>
        </div>
        <div >
            <slot />

        </div>
    </div>
</template>
<script setup>

</script>

<style scoped>
.header_div {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding: 6px;
  padding-left: 250px;
  height: 45px;
  text-align: right;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.header_div .logo {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
  width: 170px;
}
.header_div button,
.header_div a {
  margin: 0px 10px;
}
.button_containers {
    float: right;
}
</style>
